<template>
  <div class="ali-pay">
    <div class="ap-box">
      <el-card class="box-card">
        <el-alert :title="$t('AliPay.TopTip')" type="warning" show-icon>
        </el-alert>
        <div class="forms-box">
          <div class="forms-item">
            <div class="lable">{{ $t("AliPay.InputAmount") }}</div>
            <CInput
              style="margin: 0"
              type="text"
              v-model="aliForm.amount"
              autocomplete="curent-password"
              :invalid-feedback="errorBackAmount"
              :is-valid="validatorAmount"
              valid-feedback=""
            >
            </CInput>
          </div>
          <div class="forms-item">
            <div class="lable">{{ $t("AliPay.ExchangeRate") }}</div>
            <div class="huilv">{{ aliForm.huilv }}</div>
          </div>
          <div class="forms-item">
            <div class="lable">{{ $t("AliPay.PaymentAmount") }}</div>
            <div class="zfamt">{{ inputAmount }}</div>
          </div>
          <div style="text-align: center; margin-top: 2rem">
            <!-- <el-button
              @click="toPay"
              type=""
              style="background: #00bea4; color: #fff; width: 100%"
              >{{ $t("AliPay.StartPayment") }}</el-button
            > -->
            <button @click="toPay" class="btn custer sbtn" type="button">{{ $t("AliPay.StartPayment") }}</button>
          </div>
        </div>
      </el-card>
    </div>
    <el-dialog
      :visible.sync="erma"
      width="30%"
      :show-close="false"
      :close-on-click-modal="false"
      center
    >
      <div slot="title">
        <el-alert
          :title="$t('AliPay.Dialog.TopTip')"
          type="warning"
          show-icon
        ></el-alert>
      </div>
      <div style="text-align: center">
        <vueQr
          :logoSrc="erIcon"
          :text="erSrc"
          :size="size"
          :logoScale="logoScale"
        ></vueQr>
        <!-- <div class="status">Pendding</div> -->
        <!-- <div>
          <el-alert :title="orderStatus == 'succeeded' ? '充值成功' : ''" type="success"> </el-alert>
        </div> -->
        <div v-if="suc">
          <el-alert
            :title="$t('AliPay.Dialog.RechargeSuccessful')"
            type="success"
          >
          </el-alert>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="erma = false">{{
          $t("AliPay.Dialog.Close")
        }}</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { getCnyDepositRate, createAlipay } from "../../api/http";
import vueQr from "vue-qr";
export default {
  components: {
    vueQr,
  },
  data() {
    return {
      errorBackAmount: "",
      flags: false,
      erma: false,
      erIcon: require("../../assets/h-logo.jpg"),
      erSrc: "",
      order_id: "",
      orderStatus: "",
      timer: null,
      logoScale: 0.2,
      size: 200,
      suc: false,
      aliForm: {
        amount: null,
        huilv: "0.00",
      },
    };
  },
  created() {
    this.getRate();
  },
  computed: {
    // 支付金额
    inputAmount() {
      let val = Number(this.aliForm.amount) * Number(this.aliForm.huilv);
      return parseFloat(val).toFixed(1);
    },
  },
  destroyed() {
    this.timer = null;
    clearInterval(this.timer);
  },
  watch: {
    orderStatus(n) {
      console.log("orderStatus==>", n);
      if (n == "succeeded") {
        this.suc = true;
        setTimeout(() => {
          this.erma = false;
        }, 2000);
        clearInterval(this.timer);
        setTimeout(() => {
          this.$router.push("/funds/funds");
        }, 2000);
      } else {
        console.log("padding...");
        this.suc = false;
        this.erma = true;
      }
    },
  },
  methods: {
    // 获取汇率
    getRate() {
      let obj = {
        type: "get_cny_deposit_rate",
        uid: window.localStorage.getItem("uid"),
      };
      getCnyDepositRate(obj).then((res) => {
        console.log("res rate==>", res);
        if (res.code == 200) {
          this.aliForm.huilv = Number(res.data.rate);
        } else {
          this.$message.error(res.message);
        }
      });
    },
    // 开始付款
    toPay() {
      if (this.aliForm.amount == null || this.flags == true) {
        return;
      }
      let data = {
        type: "create_alipay",
        uid: window.localStorage.getItem("uid"),
        amount: this.aliForm.amount,
        is_mobile: 0,
      };
      createAlipay(data).then((res) => {
        if (res.code == 200) {
          this.erSrc = res.data.qrcode_url;
          this.order_id = res.data.order_id;
          this.erma = true;
          this.setTs();
        } else {
          this.erma = false;
          this.$message.error(res.message);
        }
      });
    },
    // 定时
    setTs() {
      clearInterval(this.timer);
      this.timer = setInterval(() => {
        this.getAdaOrder();
      }, 2000);
    },
    // 获取订单状态
    getAdaOrder() {
      let obj = {
        type: "get_ada_order",
        uid: window.localStorage.getItem("uid"),
        order_id: this.order_id,
        field: "status",
      };
      let formData = new FormData();
      for (let k in obj) {
        formData.append(k, obj[k]);
      }
      this.$axios({
        method: "POST",
        url: "/request/user.php",
        data: formData,
      }).then((res) => {
        if (res.data.code == 200) {
          console.log("order res===>", res);
          //   res.data.data.fliter((item) => (this.orderStatus = item.status));
          this.orderStatus = res.data.data[0].status;
        } else {
          this.$message.error(res.data.message);
        }
      });
    },
    validatorAmount(val) {
      console.log("val==>", val);
      let reg = /^(\+)?\d+(\.\d+)?$/;
      if (val === null) {
        this.errorBackAmount = "";
        this.flags = false;
        return;
      }
      if(val < 50)  {
        this.errorBackAmount = this.$t("AliPay.InputTip2");
        this.flags = true;
        return false;
      }
      if(val > 250)  {
        this.errorBackAmount = this.$t("AliPay.InputTip3");
        this.flags = true;
        return false;
      }
      if (reg.test(val)) {
        this.errorBackAmount = "";
        this.flags = false;
        return true;
      } 
      else {
        this.errorBackAmount = this.$t("AliPay.InputTip");
        this.flags = true;
        return false;
      }
    },
  },
};
</script>

<style scoped>
.sbtn:hover{
  background: rgba(0,190,164, .8) !important;
}
.sbtn:focus{
  border-color: #00BEA4;
  box-shadow: 0 0 0 .25rem rgba(0,190,164, .5) !important;
}
.custer{
  background: #00bea4;
  color: #fff; 
  width: 100%;
  outline:0;
}
.ali-pay {
  padding-left: 2.6rem;
}
.ap-box {
  height: 320px;
  width: 65%;
  margin: 1rem auto;
}
.forms-box {
  width: 360px;
  margin: 0.5rem auto;
}
.forms-item {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
}
.status {
  font-weight: bold;
  color: #00bea4;
  font-size: 20px;
}
</style>